import React from "react";
import { Helmet } from "react-helmet-async";

function Terms() {

    document.body.classList.add("background-home");

    return (
        <section id="terms">
            <Helmet>
                <title>Terms - EPICRUST.EU | 10x | FreeHeli | Shop | No BPs | Max 5</title>
                <meta name="description" content="Free Mini - Shop - Kits - Teleports - Always Day - Auto Doors Free Blueprints - Remover Tool - Base Upgrade - BGrade Quick Smelt - Instant Craft - Automatic Authorization No Workbench - No Radiation - Clans - Heli Vote" />
                <meta name="keywords" content="epicrust, epic rust, eu, 10x, FreeHeli, Shop, No BPs, Max 5, terms" />
            </Helmet>

            <div className="row">
                <div className="col-12">
                    <h2 className="heading animate__animated animate__fadeIn">TERMS OF USE</h2>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <ul className="text-left animate__animated animate__fadeIn">
                        <p>
                        The following Terms of Use ("Terms of Use" or "Terms") govern all use of the Epic-Rust.com website ("Website", "We", "Us" or "Our") and all content, services and products available at or through the Website.
                        </p>
                        <p>
                        YOUR USE OF THE WEBSITE SIGNIFIES THAT YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY THESE TERMS OF USE. By accessing or using the Website you also represent that you have the legal authority to accept these Terms of Use on behalf of yourself and any party you represent in connection with your use of the Website. If you do not agree to these terms, you are not authorized to use the Website.
                        </p>

                        <p className="header">1. Changes to these Terms of Use</p>
                        <p>
                            We may change the Terms of Use, and We reserve the right to do it at Our discretion. In that case, we will post the updated Terms of Use and indicate the date of revision. We encourage you to periodically review the Terms of Use. Your continued use of the Website after new and/or revised Terms of Use are effective indicates that you have read, understood and agreed to those Terms of Use.
                        </p>

                        <p className="header">2. Privacy Policy</p>
                        <p>
                            We are committed to handling responsibly the information and data We collect through the Website and agree to use your personal information in accordance with the <a href="/privacy">Privacy Policy</a> and these Terms of Use. The Privacy Policy is hereby incorporated into these Terms.
                        </p>

                        <p className="header">3. Third-Party Websites</p>
                        <p>
                            Our Website may include links to other websites. You should consult the respective terms of these third-party websites. This Terms of Use do not apply to, and we cannot control the activities of, such other websites.
                        </p>

                        <p className="header">4. Return Policy</p>
                        <p>
                            No Refunds. All of Our services are online services and nothing is shipped. We strongly recommend that you test the demo version of our dating website before making a purchase. Much like most vendors, once the online service is used or files copied (package opened) you are unable to return it for a refund. We have this policy since it would be impossible for you to return our online service. With a traditional retail purchase, you are able to return a physical product.
                        </p>

                        <p className="header">5. DISCLAIMER OF WARRANTIES</p>
                        <p>
                            TO THE FULLEST EXTENT PERMITTED BY THE APPLICABLE LAW, WE OFFER SERVICES AS-IS AND MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND CONCERNING SERVICES, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF TITLE, MERCHANTIBILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT (INCLUDING WITHOUT LIMITATION NONINFRINGEMENT FOR WORKS CONTAINED IN ANY LIST OF WORKS POSTED ON THE WEBSITE OR ANY WORK TO WHICH A BADGE IS AFFIXED). WE DO NOT WARRANT THAT THE FUNCTIONS OR CONTENT CONTAINED ON THE WEBSITE OR RELATING TO OUR SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE WEBSITE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE DO NOT WARRANT OR MAKE ANY REPRESENTATION REGARDING USE OR THE RESULT OF USE OF THE WEBSITE IN TERMS OF ACCURACY, RELIABILITY, OR OTHERWISE.
                        </p>

                        <p className="header">6. LIMITATION OF LIABILITY</p>
                        <p>
                            EXCEPT TO THE EXTENT REQUIRED BY APPLICABLE LAW AND THEN ONLY TO THAT EXTENT, IN NO EVENT WILL WE, OUR EMPLOYEES, OFFICERS, DIRECTORS, AFFILIATES OR AGENTS ("OUR PARTIES") BE LIABLE TO YOU ON ANY LEGAL THEORY FOR ANY INCIDENTAL, DIRECT, INDIRECT, PUNITIVE, ACTUAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR OTHER DAMAGES, INCLUDING WITHOUT LIMITATION, LOSS OF REVENUE OR INCOME, LOST PROFITS, PAIN AND SUFFERING, EMOTIONAL DISTRESS OR SIMILAR DAMAGES SUFFERED OR INCURRED BY YOU OR ANY THIRD PARTY THAT ARISE IN CONNECTION WITH US, OUR SRVICES (OR THE TERMINATION THEREOF FOR ANY REASON), OR THE USE OF ANY MATERIAL INCLUDED OR POSTED AT THE WEBSITE, EVEN IF WE OR OUR PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                        </p>
                        <p>
                            NOTWITHSTANDING ANYTHING TO THE CONTRARY COTAINED IN THESE TERMS OF USE, THE LIABILITY OF US AND OUR PARTIES FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID FOR PRODUCTS OR SERVICES.
                        </p>

                        <p className="header">7. Indemnification for Breach of Terms of Use</p>
                        <p>
                            You agree to indemnify and hold harmless Us and Our Parties (defined above) from and against any and all loss, expenses, damages and costs, including without limitation reasonable attorneys fees, resulting, whether directly or indirectly, from your violation of these Terms of Use or use of the Website. You also agree to indemnify and hold harmless Us and Our Parties from and against any and all claims brought by third parties arising out of your use of the Website, products and/or services.
                        </p>

                        <p className="header">8. Copyright Complaints and DMCA Compliance</p>
                        <p>
                            We respect the intellectual property rights of others. Users of the Website will not be allowed to upload, post or otherwise transmit any materials that violate another person's intellectual property rights.
                        </p>
                        <p>
                            We comply with the Digital Millennium Copyright Act (DMCA), and We respond to notices of alleged copyright infringement if they comply with the law. We reserve the right to delete or disable content alleged to be infringing and terminate accounts of infringers.
                        </p>

                        <p className="header">9. Governing Law</p>
                        <p>
                            The laws of Estonia shall govern these Terms of Use, regardless of your location. You hereby expressly consent to exclusive jurisdiction of Estonia, for all matters or disputes arising out of or relating to these Terms of Use or your access or use of the Website.
                        </p>
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default Terms;
