import React from "react";

function Footer() {
    return (
        <footer className="sticky-footer">
            <div className="row">
                <div className="col-12">

                    <div className="container">
                        <div className="left">
                          EPICRUST.EU - All Rights Reserved
                        </div>

                        <div className="right">
                          <a href="/terms">Terms</a>
                          <a href="/privacy">Privacy</a>
                        </div>
                    </div>

                </div>
            </div>
        </footer>
    );
}

export default Footer;
