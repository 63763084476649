import React from "react";
import { Helmet } from "react-helmet-async";

function Points() {

    document.body.classList.add("background-home");

    return (
        <section className="points">
            <Helmet>
                <title>Points - EPICRUST.EU | 10x | FreeHeli | Shop | No BPs | Max 5</title>
                <meta name="description" content="Free Mini - Shop - Kits - Teleports - Always Day - Auto Doors Free Blueprints - Remover Tool - Base Upgrade - BGrade Quick Smelt - Instant Craft - Automatic Authorization No Workbench - No Radiation - Clans - Heli Vote" />
                <meta name="keywords" content="epicrust, epic rust, eu, 10x, FreeHeli, Shop, No BPs, Max 5, Points" />
            </Helmet>
            <div className="row">
                <div className="col-12">
                    <h2 className="heading animate__animated animate__fadeIn">STORE POINTS</h2>
                </div>
            </div>

            <div className="row">
                <div className="points-text col-12 text-center animate__animated animate__fadeIn">
                    <p>
                        <span className="before-discount-price line-through">(24.80 EUR)</span>
                        <span className="">12.40 EUR</span>
                    </p>

                    <p>This supporter perk will give you 1 000 000 in-game store points.</p>

                    <p>Server wipes will not affect your points balance.</p>

                    <p className="font-weight-bold">MAKE SURE YOU ARE ONLINE IN RUST SERVER WHEN MAKING PAYMENT.</p>
                </div>
            </div>

            <div className="row justify-content-center">
                <a href="https://epic-rust.tebex.io/package/5450720" className="btn btn-success payment-button">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                    </svg>
                    PAYMENT
                </a>
            </div>
        </section>
    );
}

export default Points;
