import React from "react";
import { Helmet } from "react-helmet-async";

function Commands() {

    document.body.classList.add("background-commands");

    return (
            <section id="commands">
            <Helmet>
                <title>Commands - EPICRUST.EU | 10x | FreeHeli | Shop | No BPs | Max 5</title>
                <meta name="description" content="Free Mini - Shop - Kits - Teleports - Always Day - Auto Doors Free Blueprints - Remover Tool - Base Upgrade - BGrade Quick Smelt - Instant Craft - Automatic Authorization No Workbench - No Radiation - Clans - Heli Vote" />
                <meta name="keywords" content="epicrust, epic rust, eu, 10x, FreeHeli, Shop, No BPs, Max 5, commands" />
            </Helmet>
<div class="row">
    <div class="col-12">
        <h2 class="heading animate__animated animate__fadeIn">COMMANDS</h2>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <ul class="text-left animate__animated animate__fadeIn">
            <p><span class="red">/s </span> shop -- <span class="red">/kit </span> get kits -- <span class="red">/remove </span> remove walls</p>
            <p><span class="red">/mymini </span> spawn mini heli -- <span class="red">/nomini </span> remove heli</p>
            <p><span class="red">/tpr &lt;player name&gt; </span> send teleport request -- <span class="red">/tpa </span> accept teleport request</p>
            <p><span class="red">/sethome &lt;home name&gt; </span> set teleport point -- <span class="red">/home &lt;home name&gt; </span> teleport</p>
            <p><span class="red">/removehome &lt;home name&gt; </span> remove teleport point -- <span class="red">/home list </span> show saved teleport points</p>
            <p><span class="red">/outpost </span> teleport to outpost -- <span class="red">/bandit </span> teleport to bandit camp</p>
            <p><span class="red">/tpat </span> toggle on/off automatically accepting teleports from clan members</p>
            <p><span class="red">/clan create &lt;clan name&gt; </span> create clan -- <span class="red">/clan disband forever </span> disband clan</p>
            <p><span class="red">/clan invite &lt;player name&gt; </span> invite player to clan -- <span class="red">/clan join &lt;clan name&gt; </span>accept clan invite</p>
            <p><span class="red">/clan withdraw &lt;player name&gt; </span> revoke clan invite -- <span class="red">/clan kick &lt;player name&gt; </span>kick clan member</p>
            <p><span class="red">/cff </span> enable or disable friendly fire</p>
            <p><span class="red">/pm &lt;player name&gt; &lt;message&gt; </span> send message -- <span class="red">/r &lt;message&gt; </span> reply to last message</p>
            <p><span class="red">/bgrade &lt;1 wood, 2 stone, 3 metal, 4 HQM, 0 stop upgrading&gt; </span> auto upgrade while building</p>
            <p><span class="red">/ad </span> enable or disable automatic door closing</p>
            <p><span class="red">/helivote open &lt;1-3&gt; </span> start vote to spawn in patrol heli</p>
        </ul>
    </div>
</div>

</section>
    );
}

export default Commands;
