import React, { useState } from "react";
import { Helmet } from 'react-helmet-async';

const FORM_ENDPOINT = "https://public.herotofu.com/v1/5600d930-8700-11ee-9596-0b78e368ddf0";

const Contact = (props) => {

    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (e) => {  

        e.preventDefault();

        const inputs = e.target.elements;
        const data = {};

        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].name) {
                data[inputs[i].name] = inputs[i].value;
            }
        }

    fetch(FORM_ENDPOINT, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error('Form response was not ok');
        }
        setSubmitted(true);
      })
      .catch((err) => {
          // Submit the form manually
          e.target.submit();
      });
    };

    document.body.classList.add("background-home");

    return (
        <section id="landing-page">
            <Helmet>
                <title>Contact Us - EPICRUST.EU | 10x | FreeHeli | Shop | No BPs | Max 5</title>
                <meta name="description" content="Free Mini - Shop - Kits - Teleports - Always Day - Auto Doors Free Blueprints - Remover Tool - Base Upgrade - BGrade Quick Smelt - Instant Craft - Automatic Authorization No Workbench - No Radiation - Clans - Heli Vote" />
                <meta name="keywords" content="epicrust, epic rust, eu, 10x, FreeHeli, Shop, No BPs, Max 5, contact us" />
            </Helmet>

            <div className="contact-us-wrapper">
                <div className="server animate__animated animate__fadeIn">

                    <div className="container" id="contact-form">
                        {(submitted || props.message === "sent") ?  
                            <div className="text-center">
                                <a className="btn-get-started btn btn-success mt-5 mb-5" href="/">MESSAGE SENT, THANK YOU</a>
                            </div>
                        : 
                            <>
                                <div className="contact-us-header">CONTACT US</div>
                                <div className="row">
                                    <div className="col-lg-12 mt-0">

                                      <form action={FORM_ENDPOINT} onSubmit={handleSubmit} method="POST">
                                            <input type="text" placeholder="Your name" name="name" required />
                                            <input type="email" placeholder="Your Email" name="email" required />
                                            <textarea placeholder="Message" name="message" rows="5" required></textarea>
                                            <div className="text-center">
                                                <button type="submit" className="btn-get-started btn btn-success">Send Message</button>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </>
                          }
                    </div>

                </div>
            </div>
        </section>
    );
};

export default Contact;
