import React from "react";
import { Helmet } from "react-helmet-async";

function Rules() {

    document.body.classList.add("background-rules");

    return (
        <section id="rules">
            <Helmet>
                <title>Rules - EPICRUST.EU | 10x | FreeHeli | Shop | No BPs | Max 5</title>
                <meta name="description" content="Free Mini - Shop - Kits - Teleports - Always Day - Auto Doors Free Blueprints - Remover Tool - Base Upgrade - BGrade Quick Smelt - Instant Craft - Automatic Authorization No Workbench - No Radiation - Clans - Heli Vote" />
                <meta name="keywords" content="epicrust, epic rust, eu, 10x, FreeHeli, Shop, No BPs, Max 5, rules" />
            </Helmet>

            <div className="row rules-heading">
                <div className="col-12">
                    <h2 className="heading animate__animated animate__fadeIn">RULES</h2>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <ul className="text-left animate__animated animate__fadeIn">
                        <p>1 - Keep the game play fair and fun for everyone.</p>
                        <p>2 - Do not use third party tools to give yourself an advantage over other players.</p>
                        <p>3 - No more than 5 players in a group.</p>
                        <p>4 - No toxicity, racism, advertising or spamming.</p>
                        <p>5 - Avoid posting any personal information either about yourself or others.</p>
                        <p>6 - Do not build any large (more then 10 foundations wide) structures that lag the server. </p>
                        <p>7 - Breaking the rules results in a perma ban without warning and removal of base and loot.</p>
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default Rules;
