import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Rules from "./pages/Rules";
import Commands from "./pages/Commands";
import Contact from "./pages/Contact";

import Supporter from "./pages/Supporter";

import Silver from "./pages/Silver";
import Gold from "./pages/Gold";
import Platinum from "./pages/Platinum";
import Points from "./pages/Points";



import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Footer from "./components/Footer";


function App() {

  const location = useLocation();

  return (
    
      
        
    <HelmetProvider>
      <Navbar page={location.pathname} />
      <Routes>
        <Route path="/contact" element={<Contact />} />
        <Route path="/message-received" element={<Contact message="sent" />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="/commands" element={<Commands />} />
        <Route path="/supporter" element={<Supporter />} />
        <Route path="/silver" element={<Silver />} />
        <Route path="/gold" element={<Gold />} />
        <Route path="/platinum" element={<Platinum />} />
        <Route path="/points" element={<Points />} />


        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </HelmetProvider>
    

  );
}

export default App;
