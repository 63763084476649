import React from "react";
import { Helmet } from "react-helmet-async";

function Supporter() {

    document.body.classList.add("background-supporter");

    return (
        <section id="supporter">
            <Helmet>
                <title>Supporter Perks - EPICRUST.EU | 10x | FreeHeli | Shop | No BPs | Max 5</title>
                <meta name="description" content="Free Mini - Shop - Kits - Teleports - Always Day - Auto Doors Free Blueprints - Remover Tool - Base Upgrade - BGrade Quick Smelt - Instant Craft - Automatic Authorization No Workbench - No Radiation - Clans - Heli Vote" />
                <meta name="keywords" content="epicrust, epic rust, eu, 10x, FreeHeli, Shop, No BPs, Max 5, Supporter Perks" />
            </Helmet>
            <div className="row">
                <div className="col-12">
                    <h2 className="heading animate__animated animate__fadeIn" style={{ color: '#cd412b' }}>SUPPORTER PERKS<br />WE SLASHED PRICES BY 50%</h2>
                </div>
            </div>

            <div className="row justify-content-center">    
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="member-container d-flex justify-content-center">

                        <div className="option d-flex flex-column server animate__animated animate__fadeIn">

                            <img loading="lazy" src="assets/img/silver.png" alt="Silver Perks" />

                            <div className="option-description">                    
                                <span className="option-name">SILVER</span>

                                <span className="before-discount-price line-through">(12.40 EUR)</span>
                                <span className="option-price">6.20 EUR</span>

                                <a href="/silver" className="btn btn-secondary">
                                    INFO
                                </a>

                                <a href="https://epic-rust.tebex.io/package/5284852" className="btn btn-success">                                               
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                    </svg>
                                    PAY
                                </a>
                            </div>

                        </div>

                    </div>
                </div>
                
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="member-container d-flex justify-content-center">

                        <div className="option d-flex flex-column server animate__animated animate__fadeIn">

                            <img loading="lazy" src="assets/img/gold.png" alt="Gold Perks" />

                            <div className="option-description">                    
                                <span className="option-name">GOLD</span>

                                <span className="before-discount-price line-through">(18.60 EUR)</span>
                                <span className="option-price">9.30 EUR</span>

                                <a href="/gold" className="btn btn-secondary">
                                    INFO
                                </a>

                                <a href="https://epic-rust.tebex.io/package/5286374" className="btn btn-success">                                               
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                    </svg>
                                    PAY
                                </a>
                                
                                <span className="most-popular">MOST POPULAR</span>
                            </div>

                        </div>

                    </div>
                </div>
                
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="member-container d-flex justify-content-center">

                        <div className="option d-flex flex-column server animate__animated animate__fadeIn">

                            <img loading="lazy" src="assets/img/platinum.png" alt="Platinum Perks" />

                            <div className="option-description">                    
                                <span className="option-name">PLATINUM</span>

                                <span className="before-discount-price line-through">(24.80 EUR)</span>
                                <span className="option-price">12.40 EUR</span>

                                <a href="/platinum" className="btn btn-secondary">
                                    INFO
                                </a>

                                <a href="https://epic-rust.tebex.io/package/5284867" className="btn btn-success">                                               
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                    </svg>
                                    PAY
                                </a>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="member-container d-flex justify-content-center">

                        <div className="option d-flex flex-column server animate__animated animate__fadeIn">

                            <img loading="lazy" src="assets/img/points.png" alt="Points" />

                            <div className="option-description">                    
                                <span className="option-name">1 Million<br />POINTS</span>

                                <span className="before-discount-price line-through">(24.80 EUR)</span>
                                <span className="option-price">12.40 EUR</span>

                                <a href="/points" className="btn btn-secondary">
                                    INFO
                                </a>

                                <a href="https://epic-rust.tebex.io/package/5450720" className="btn btn-success">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                    </svg>
                                    PAY
                                </a>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Supporter;
