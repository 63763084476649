import React from "react";
import { Helmet } from "react-helmet-async";

function Privacy() {
  
  document.body.classList.add("background-home");

  return (
    <section id="terms">
      <Helmet>
        <title>Privacy - EPICRUST.EU | 10x | FreeHeli | Shop | No BPs | Max 5</title>
        <meta name="description" content="Free Mini - Shop - Kits - Teleports - Always Day - Auto Doors Free Blueprints - Remover Tool - Base Upgrade - BGrade Quick Smelt - Instant Craft - Automatic Authorization No Workbench - No Radiation - Clans - Heli Vote" />
        <meta name="keywords" content="epicrust, epic rust, eu, 10x, FreeHeli, Shop, No BPs, Max 5, privacy" />
      </Helmet>

      <div className="row">
          <div class="col-12">
              <h2 className="heading animate__animated animate__fadeIn">PRIVACY POLICY</h2>
          </div>
      </div>

      <div className="row">
          <div className="col-12">
              <ul className="text-left animate__animated animate__fadeIn">
                <p>
                  This privacy policy ("Privacy Policy" or "Policy") governs the manner in which Epic-Rust.com website ("Website", "We", "Us" or "Our") collects, uses, maintains and discloses information collected from users at Website.
                </p>
                <p>
                  YOUR USE OF THE WEBSITE SIGNIFIES THAT YOU HAVE READ, UNDERSTAND AND AGREE TO THIS PRIVACY POLICY. By accessing or using the Website you also represent that you have the legal authority to accept this Privacy Policy on behalf of yourself and any party you represent in connection with your use of the Website. If you do not agree to Privacy Policy, you are not authorized to use the Website.
                </p>

                <p className="header">1. Principles</p>
                <p>
                  - We respect your privacy regarding any information We collect.<br/>
                  - We ask personally-identifying information only if we truly need it.<br />
                  - We will not sell personally-identifying information to anyone.<br />
                  - We will not send any kind of spam emails.
                </p>

                <p className="header">2. Website Visitors</p>
                <p>
                  Like most website operators, We collect non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Our purpose in collecting non-personally identifying information is to better understand how visitors use the Website. From time to time We may release non-personally-identifying aggregated information, for example, by publishing a report on trends about the usage of the Website.
                </p>

                <p className="header">
                  3. Gathering of Personally-Identifying Information
                </p>
                <p>
                  The amount and type of information that We gather depends on the nature of the interaction. For example, we ask visitors who sign up for an account at the Website to provide age, gender, country and email address. In each case We collect information only insofar as is necessary or appropriate to fulfill the purpose of the visitors interaction with the Website. We do not disclose personally-identifying information other than as described in this Privacy Policy. And visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from engaging in certain Website related activities.
                </p>

                <p className="header">4. Using Personally-Identifying Information</p>
                <p>
                  We disclose potentially personally-identifying and personally-identifying information only to those of Our employees, contractors and affiliated organizations that need to know that information in order to process it on Our behalf or to provide services available at the Website. Some of those employees, contractors and affiliated organizations may be located outside of your home country. By using the Website, you consent to the transfer of such information to them. We will not rent or sell personally-identifying information to anyone. Other than to Our employees, contractors and affiliated organizations, as described above, We disclose potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when We believe in good faith that disclosure is reasonably necessary to protect Our property or rights, third parties or the public at large. If you are a registered user of the Website and have supplied Us your email address, We may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with our services and products. If you send Us a request (for example via a support email), We reserve the right to publish it in order to help Us clarify or respond to your request or to help Us support other users. We take all measures that are reasonable and necessary to protect Out systems against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.
                </p>

                <p className="header">5. Cookies</p>
                <p>
                  Cookie is a string of information that website stores on a visitors computer, and that the visitor's browser provides to the website each time the visitor returns. We may use cookies to improve the Website. Visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using the Website, with the drawback that certain features may not function properly without the aid of cookies.
                </p>

                <p className="header">6. Business Transfers</p>
                <p>
                  If Us, or the Website were acquired, user information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of Us or the Website may continue to use your personal information as set forth in this Privacy Policy.
                </p>

                <p className="header">7. Third-Party Content</p>
                <p>
                  Third-party content appearing on the Website may be delivered to users by our partners, who may set cookies. These cookies allow the partner to recognize your computer each time you interact with the content to compile information about you or others who use your computer. This Privacy Policy covers the use of cookies by Us and does not cover the use of cookies by any partners.
                </p>

                <p className="header">8. Third-Party Websites</p>
                <p>
                  Website may include links to other websites. You should consult the respective privacy policies of these third-party sites. This Privacy Policy does not apply to, and we cannot control the activities of, such other websites.
                </p>

                <p className="header">9. Where We Store Your Personal Data</p>
                <p>
                  The data that we collect from you may be transferred to, and stored at, a destination outside the European Economic Area ("EEA"). It may also be processed by staff operating outside the EEA who works for us or for one of our suppliers. Such staff maybe engaged in, among other things, the fulfillment of your order, the processing of your payment details and the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy.
                </p>

                <p className="header">10. Privacy Policy Changes</p>
                <p>
                  We reserve the right, at our sole discretion, to change, add or remove portions of this Policy at any time by posting such changes to this page. We encourage you to periodically review this Privacy Policy to stay informed about how We are protecting the personal information We collect. Your continued use of the Website constitutes your agreement to this Privacy Policy and any updates.
                </p>
                  
              </ul>
          </div>
      </div>
    </section>
  );
}

export default Privacy;
