import React from "react";
import { Helmet } from "react-helmet-async";

function Home() {

    document.body.classList.add("background-home");

    return (
        <section id="landing-page">
            <Helmet>
                <title>EPICRUST.EU - 10x | FreeHeli | Shop | No BPs | Max 5</title>
                <meta name="description" content="Free Mini - Shop - Kits - Teleports - Always Day - Auto Doors Free Blueprints - Remover Tool - Base Upgrade - BGrade Quick Smelt - Instant Craft - Automatic Authorization No Workbench - No Radiation - Clans - Heli Vote" />
                <meta name="keywords" content="epicrust, epic rust, eu, 10x, FreeHeli, Shop, No BPs, Max 5" />
                <link rel="canonical" href="/" />
            </Helmet>

            <div className="row">
                <div className="col-12"></div>
            </div>


            <div class="row justify-content-center">
                <div className="server-info-wrapper">
                    <div className="server animate__animated animate__fadeIn">

                        <div className="server-image-container">
                            <img className="server-image img-fluid" src="assets/img/server-image.jpg" alt="EPICRUST.EU" title="EpicRust" />
                            <div className="server-image-overlay"></div>
                        </div>

                        <div className="server-container">
                            <span className="server-name">10x | FreeHeli | Shop | No BPs | Max 5</span>
                            <div className="server-tags" style={{ padding: 10 }}>
                                <span className="server-tag mx-1">Europe</span>
                                <span className="server-tag mx-1">Weekly</span>
                            </div>

                            <div className="server-description">
                                Press F1 while in game and paste: "connect 65.21.22.47:28065" to connect, or search epicrust from in-game search.<br /><br />
                            
                                Welcome Bonus 10,000 Shop Points<br /><br />

                                Free Mini - Shop - Kits - Teleports - Always Day - Auto Doors<br />
                                Free Blueprints - Remover Tool - Base Upgrade - BGrade<br />
                                Quick Smelt - Instant Craft - Automatic Authorization<br />
                                No Workbench - No Radiation - Clans - Heli Vote<br /><br />

                                Wipe is once a week, Friday 17.00 CET
                            </div>
                        </div>

                    </div>
                    </div>
                </div>

        </section>
    );
}

export default Home;
