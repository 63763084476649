import React from "react";
import { Helmet } from "react-helmet-async";

function Platinum() {

    document.body.classList.add("background-home");

    return (
        <section className="perks">
            <Helmet>
                <title>Platinum Supporter - EPICRUST.EU | 10x | FreeHeli | Shop | No BPs | Max 5</title>
                <meta name="description" content="Free Mini - Shop - Kits - Teleports - Always Day - Auto Doors Free Blueprints - Remover Tool - Base Upgrade - BGrade Quick Smelt - Instant Craft - Automatic Authorization No Workbench - No Radiation - Clans - Heli Vote" />
                <meta name="keywords" content="epicrust, epic rust, eu, 10x, FreeHeli, Shop, No BPs, Max 5, Platinum Supporter" />
            </Helmet>
            <div className="row">
                <div className="col-12">
                    <h2 className="heading animate__animated animate__fadeIn">PLATINUM SUPPORTERS</h2>
                </div>
            </div>

            <div className="row">
                <div className="col-12 text-left animate__animated animate__fadeIn">           
                    <p>Platinum supporters (12.40 <span className="line-through">24.80</span> EUR) will get following perks for 30 days:</p>
                    <p>
                        30 Teleport Points (default 2)<br />
                        5 Second Teleport Countdown (default 20)<br />
                        15 Second Mini Cooldown (default 600)<br />
                        Platinum Resources Kit - After Every 24 Hours<br />
                        Platinum PVP Kit - After every 4 hours
                    </p>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-sm-6">
                    <div className="d-flex justify-content-center animate__animated animate__fadeIn">
                        <div className="kit d-flex flex-column">
                            Platinum Resources Kit
                            <img loading="lazy" src="assets/img/platinum_resources_kit.jpg" alt="Platinum Resources Kit" />
                        </div>
                    </div>
                </div>

                <div className="col-sm-6">
                    <div className="d-flex justify-content-center animate__animated animate__fadeIn">
                        <div className="kit d-flex flex-column">
                            Platinum PVP Kit
                            <img loading="lazy" src="assets/img/platinum_pvp_kit.jpg" alt="Platinum PVP Kit" />
                        </div>
                    </div>
                </div>

                <a href="https://epic-rust.tebex.io/package/5284867" className="btn btn-success payment-button">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                    </svg>
                    PAYMENT
                </a>
            </div>
        </section>
    );
}

export default Platinum;
